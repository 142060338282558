/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/combined/'], ['en', 'https://www.hear.com/hearing-loss/combined/'], ['en-US', 'https://www.hear.com/hearing-loss/combined/'], ['en-CA', 'https://ca.hear.com/hearing-loss/combined/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "description",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#description",
    "aria-label": "description permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Description"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Combined hearing loss describes the occurrence of conductive hearing loss that also has a sensorineural component. The adverse effects result from the component that is more pronounced."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The treatment method takes its cue from the severity of the hearing loss but also depends on its make-up. It may suffice to manage minor or temporary hearing loss medically. With the beginning of a moderately severe hearing loss, however, a hearing aid is generally prescribed. In very profound cases of combined hearing loss, a bone conduction implant may be carried out. The ENT doctor or a hearing aid provider usually decides upon treatment methods in order to increase your quality of life and health."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes",
    "aria-label": "causes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/combined-hearing-loss.png",
    alt: "Combined hearing loss representation",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Combined hearing loss may arise from a chronic ", React.createElement(_components.a, {
    href: "/useful-knowledge/otitis-media/",
    className: "c-md-a"
  }, "middle ear infection"), " or otosclerosis involving the inner ear. However, multiple independent causes for conductive and sensorineural hearing loss may also be factors."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment",
    "aria-label": "treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As combined hearing loss is a mixture of conductive and sensorineural hearing loss, the treatment varies significantly among patients. Therefore, an individual solution needs to be found for every person. Medication is often used for temporary and weak combined hearing losses. In moderate and permanent cases hearing aids provide a satisfying remedy. Besides this page, please also refer to our other resources on conductive and sensorineural hearing loss for more individual details."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Need further advice and help on combined or other types of hearing loss? Contact our experts for a non-binding and free consultation now. We want to find an optimal solution with you together."), "\n", React.createElement(ButtonCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
